@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
    outline: transparent;
  }
  :root {
    --nav-bar: 4rem;
    --company-color: theme('colors.companyColor');
    --company-color-secondary:theme('colors.companyColorSecondary');
    --button-color: theme('colors.buttonColor');
    --on-primary-color: theme('colors.onprime');
    --nav-bg-color: theme('colors.navbg');
    --nav-text-color: theme('colors.navtext');
    --color-shadow-card: rgba(0, 0, 0, 0.08);
    --error-text: theme('colors.error.text');
  }
  *:focus {
    outline: none;
  }
}

body {
  @apply text-[#222] bg-backgroundColor font-sans;
}

.trading-header-row th {
  padding-left: 0.2rem;
  background: white;
  padding-bottom: 0.3rem;
  padding-top: 0.3rem;
  position: sticky;
  border-bottom: 2px solid black;
  @apply top-16 md:top-28;
}

.border-spacing-0 {
  border-spacing: 0px 0px;
}

.trading-row td {
  padding-left: 0.2rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.sharp {
  background: linear-gradient(to bottom, rgb(211, 211, 211, 0), rgb(211, 211, 211, 0.25));
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid black;
  -webkit-transition: -webkit-transform 150ms ease-in-out;
  -ms-transition: -ms-transform 150ms ease-in-out;
  transition: transform 150ms ease-in-out;
}

.arrow-rotate {
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.cont {
  @apply max-w-full px-2 md:px-4 md:max-w-[992px] lg:max-w-[1280px] mb-[var(--nav-bar)] lg:mb-0 lg:mt-[var(--nav-bar)];
}

/* chart styles from flowbite */
.apexcharts-tooltip {
  @apply bg-white dark:bg-gray-700 text-gray-500 dark:text-gray-400 border-0 rounded-lg shadow-lg !important;
}

.apexcharts-tooltip .apexcharts-tooltip-title {
  @apply py-2 px-4 bg-gray-100 dark:bg-gray-600 border-b border-gray-200 dark:border-gray-500 !important;
}

.apexcharts-xaxistooltip {
  @apply text-gray-500 border-0 bg-white dark:bg-gray-700 dark:text-gray-300 rounded-lg shadow-lg !important;
}

.apexcharts-tooltip .apexcharts-tooltip-text-y-value {
  @apply dark:text-white;
}

.apexcharts-xaxistooltip-text {
  @apply font-medium text-sm !important;
}

.apexcharts-xaxistooltip:before,
.apexcharts-xaxistooltip:after {
  @apply border-0 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  @apply bg-green-500 !important;
}

/* DNB Custom */

@keyframes opacityTransition {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

.appear-test {
  animation: 1s ease-in-out 0s 1 opacityTransition;
  background-color: gray;
  @apply fixed top-0 left-0 right-0 bottom-0 opacity-50;
}


@media print {
  @page {
    /*size: landscape;*/
    margin: 10mm; /* adjust as needed */
  }

  /*header + div {*/
  /*  top: 50px;*/
  /*}*/

  header {
    page-break-before: always;
  }

  .performanceCardContainer {
    flex-direction: row;
    gap: 5px;
  }

  .portfolioItemsContainer {
    transform: scale(0.75);
    transform-origin: top left;
    width: 133%;
    page-break-before: always;
    padding-top: 50px;
  }

  .pieChartContainer {
    page-break-before: always;
    padding-top: 50px;
  }

  body {
    box-sizing: border-box;
  }

  /* Hide elements that shouldn't be printed */
  .no-print {
    display: none;
  }
}